<template>
  <div class="queryDetails">
    <!-- 简易订餐 -->
    <van-nav-bar
      :title="name || '订餐'"
      left-text
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="overflow_div">
      <!-- 查询条件--日期 -->
      <div class="date_content">
        <div class="year_box">
          <div class="icon_box" @click="arrowsYear('left')">
            <van-icon name="arrow-left" />
          </div>
          <div
            class="year_btn"
            v-for="(it, idx) in years"
            :key="idx"
            :class="[
              1 == 1 ? 'cao' + idx : '',
              checkedYear == it ? 'checkd_year' : ''
            ]"
            @click="getOneYear(it)"
          >
            <div class="year">{{ it + '年' }}</div>
          </div>
          <div class="icon_box" @click="arrowsYear('right')">
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="month_box">
          <div class="month_btn" v-for="(it, idx) in months" :key="idx">
            <div
              class="month"
              :class="checkedMonth == it ? 'checkd_Month' : ''"
              @click="getOneMonth(it)"
            >
              {{ it + '月' }}
            </div>
          </div>
        </div>
        <div class="table_content" :style="tabStyle">
          <div class="auto_table">
            <table ref="table" class="table">
              <!-- 表头 -->
              <tr class="top">
                <th
                  v-for="(item_th, index_th) in thList"
                  :key="index_th"
                  v-show="item_th.chnname || index_th == 0"
                >
                  <div class="title">
                    <template v-if="index_th > 0 && index_th < 6">
                      <img
                        :src="require('@assets/img/p0' + index_th + '.png')"
                        alt=""
                      />
                    </template>
                    {{ item_th.chnname }}
                  </div>
                </th>
              </tr>
              <!--展示列表数据 -->
              <tr
                class="cont"
                v-for="(item_tr, index_tr) in bookinfo"
                :key="index_tr"
              >
                <td
                  v-for="(item_td, index_td) in thList"
                  :key="index_td"
                  :class="{
                    disabled:
                      (!item_tr['can_book' + index_td] &&
                        item_td.fieldname != 'yymmdd2') ||
                      (item_td.fieldname == 'yymmdd2' &&
                        !item_tr.can_book1 &&
                        !item_tr.can_book2 &&
                        !item_tr.can_book3 &&
                        !item_tr.can_book4 &&
                        !item_tr.can_book5)
                  }"
                  v-show="item_td.chnname || index_td == 0"
                >
                  <div class="comm_box">
                    <div
                      class="boolean_box"
                      v-if="item_td.fieldname != 'yymmdd2'"
                    >
                      <van-checkbox
                        v-model="item_tr[item_td.fieldname]"
                        :disabled="!item_tr['can_book' + index_td]"
                        :class="{
                          hasbook: initbookinfo[index_tr][item_td.fieldname]
                        }"
                      ></van-checkbox>
                      <!-- {{initbookinfo[index_tr][item_td.fieldname]}} -->
                    </div>
                    <div class="text_box" v-else>
                      {{ item_tr[item_td.fieldname] }}
                    </div>
                  </div>
                </td>
              </tr>
              <!-- 最后一行的合计数据 需要高亮 -->
              <tr class="cont sum">
                <td
                  v-for="(item_th, index_th) in thList"
                  :key="index_th"
                  v-show="item_th.chnname || index_th == 0"
                >
                  <div class="comm_box">
                    <div
                      class="boolean_box"
                      v-if="item_th.fieldname != 'yymmdd2'"
                    >
                      <van-checkbox
                        v-model="all['book' + index_th]"
                        @change="changeAll(index_th)"
                      >
                      </van-checkbox>
                    </div>
                    <div v-else>
                      批量选择
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-button
        type="info"
        size="small"
        :disabled="!canOrder"
        @click="savePersonInfo"
        >{{ $t('module.submit') }}</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { parseTime } from '@/utils'
import { getSimpleBookMonthInfo, saveSimpleBookMonthInfo } from '@api/wx.js'
export default {
  data() {
    const fromH5 = this.$route.query.fromH5
    const name = this.$route.query.name
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const param = this.$route.query.param
    return {
      userInfo,
      param, // 从外部过来的参数
      fromH5, // 是否从H5过来的
      name, //传入的标题
      years: [], // 年份数组
      checkedYear: '', // 选中的年份
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // 月份数组
      checkedMonth: '', // 选中的月份
      bookinfo: [], // 员工的点菜信息
      initbookinfo: [], // 初始的员工的点菜信息
      loading: false,
      empid: 0, // 当前登录的empid
      langid: 0, // 当前多语言Id
      empinfo: {},
      tabStyle: 'height:100%', // 表格高度
      thList: [
        {
          fieldname: 'yymmdd2',
          chnname: ''
        },
        {
          fieldname: 'book1',
          chnname: '早餐'
        },
        {
          fieldname: 'book2',
          chnname: '中餐'
        },
        {
          fieldname: 'book3',
          chnname: '晚餐'
        },
        {
          fieldname: 'book4',
          chnname: '夜宵'
        },
        {
          fieldname: 'book5',
          chnname: '其他'
        }
      ],
      all: {
        book1: false,
        book2: false,
        book3: false,
        book4: false,
        book5: false
      }
    }
  },
  computed: {
    // 是否显示提交和编辑
    canOrder() {
      return JSON.stringify(this.initbookinfo) != JSON.stringify(this.bookinfo)
    }
  },
  created() {
    // 获取年
    this.getYearsByDate(new Date())
    // 第一次进来默认当前日期
    this.checkedMonth = new Date().getMonth() + 1
    if (this.fromH5 == true || this.fromH5 == 'true') {
      // console.log('H5过来的');
      this.empid = this.userInfo.empid
      this.langid = localStorage.getItem('langid')
      this.getAllPageDatas('')
    } else {
      // console.log('外部过来的');
      this.empid = this.$route.query.empid
      this.langid = this.$route.query.langid
      this.param = this.$route.query.param
      // 查询页面
      this.getAllPageDatas(this.param)
    }
  },
  mounted() {
    const dateContent = document
      .getElementsByClassName('overflow_div')[0]
      .querySelector('.date_content')
    const dateContent2 = document
      .getElementsByClassName('queryDetails')[0]
      .querySelector('.footer')
    const dateH = dateContent.clientHeight + dateContent2.clientHeight
    this.tabStyle = 'height: calc(100vh - ' + dateH + 'px)'
  },
  methods: {
    // 根据日期获取年份列表
    getYearsByDate(v) {
      if (v) {
        let date = new Date(v)
        let year = date.getFullYear()
        this.years = [year - 1, year, year + 1]
        //
        this.checkedYear = year
      }
    },
    // 不够就补零
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate(date, rule) {
      if (date) {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd') {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else {
        return ''
      }
    },
    // 返回
    onClickLeft() {
      if (this.$route.query.from == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/personCen')
      }
    },
    // 点击选择某个月份
    getOneMonth(v) {
      if (this.checkedMonth == v &&false) {
        this.checkedMonth = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else {
        this.checkedMonth = v
        if (this.checkedYear) {
          this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
          // 查询页面
          this.getAllPageDatas(this.param)
        }
      }
    },
    // 点击选择某个年份
    getOneYear(v) {
      if (this.checkedYear == v &&false) {
        this.checkedYear = ''
        this.param = ''
        // 查询页面
        this.getAllPageDatas(this.param)
      } else {
        this.checkedYear = v
        this.param = this.checkedYear + '' + this.add0(this.checkedMonth)
        // 查询页面
        this.getAllPageDatas(this.param)
      }
    },
    // 箭头切换年份
    arrowsYear(v) {
      if (v == 'left') {
        this.years.unshift(this.years[0] - 1)
        this.years.pop()
      } else {
        this.years.push(this.years[2] + 1)
        this.years.shift()
      }
    },
    // 提交按钮
    savePersonInfo() {},
    // 获取页面所有信息
    getAllPageDatas(yymm) {
      getSimpleBookMonthInfo({
        yymm: yymm || this.checkedYear + '' + this.add0(this.checkedMonth),
        empid: this.empid,
        langid: this.langid
      }).then(res => {
        this.all = {
          book1: false,
          book2: false,
          book3: false,
          book4: false,
          book5: false
        }
        this.$nextTick(() => {
          res.bookinfo.forEach(e => {
            e.yymmdd2 = parseTime(
              new Date(e.yymmdd.replace(/-/g, '/')),
              '{d}({a})'
            )
          })
          this.bookinfo = res.bookinfo
          this.empinfo = res.empinfo[0]
          this.thList[1].chnname = this.empinfo.bookname1
          this.thList[2].chnname = this.empinfo.bookname2
          this.thList[3].chnname = this.empinfo.bookname3
          this.thList[4].chnname = this.empinfo.bookname4
          this.thList[5].chnname = this.empinfo.bookname5
          this.initbookinfo = JSON.parse(JSON.stringify(res.bookinfo))
        })
      })
    },
    // 保存订餐
    savePersonInfo() {
      let data = {
        yymm: this.empinfo.yymm,
        empid: this.empinfo.empid,
        data: []
      }
      this.bookinfo.forEach((e, i) => {
        const e2 = this.initbookinfo[i]
        const obj = {
          yymmdd: e.yymmdd,
          old_book1: e2.book1 ? true : false,
          new_book1: e.book1 ? true : false,
          old_book2: e2.book2 ? true : false,
          new_book2: e.book2 ? true : false,
          old_book3: e2.book3 ? true : false,
          new_book3: e.book3 ? true : false,
          old_book4: e2.book4 ? true : false,
          new_book4: e.book4 ? true : false,
          old_book5: e2.book5 ? true : false,
          new_book5: e.book5 ? true : false
        }
        data.data.push(obj)
      })
      // console.log(data, '1111111111')
      saveSimpleBookMonthInfo(data).then(res => {
        if (res.data[0].info) {
          Toast.fail('订餐失败！' + res.data[0].info)
        } else {
          Toast.success('订餐成功！')
        }
        this.getAllPageDatas(data.yymm)
      })
    },
    // 批量选择
    changeAll(i) {
      // console.log(i, this.all['book' + i])
      this.bookinfo.forEach(e => {
        if (e['can_book' + i]) {
          e['book' + i] = this.all['book' + i]
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.queryDetails {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 100px);
    .date_content {
      .year_box {
        display: flex;
        align-items: center;
        padding: 20px 0;
        background: #ffffff;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          width: calc((100% - 40px) / 3);
          color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background-color: #eff6ff;
          border-radius: 10px;
          .year {
            padding: 10px 0;
          }
        }
        .cao0 {
          margin: 0 20px 0 10px;
        }
        .cao1 {
          margin-right: 20px;
        }
        .cao2 {
          margin-right: 10px;
        }
        .checkd_year {
          color: #ffffff;
          background: #5398ff;
        }
      }
      .month_box {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        padding-bottom: 10px;
        .month_btn {
          width: calc(100% / 6);
          color: #aeaeae;
          font-size: 28px;
          text-align: center;
          .month {
            padding: 10px 0;
            margin: 10px;
          }
          .checkd_Month {
            color: #5095ff;
            background: #ffffff;
            border-radius: 30px;
            border: 1px solid #5095ff;
          }
        }
      }
      .date_box {
        .head_date {
          display: flex;
          align-items: center;
          padding: 20px 0;
          background: #fff;
          .icon_box {
            display: flex;
            align-items: center;
            .van-icon {
              font-size: 40px;
              font-weight: bold;
            }
          }
          .date_box {
            width: calc((100% - 40px) / 7);
            font-size: 28px;
            text-align: center;
            .week {
              margin-bottom: 10px;
            }
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              .text {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
              }
              .bgtext {
                color: #fff;
                background: #2b8df0;
              }
            }
          }
        }
      }
    }
    .table_content {
      .auto_table {
        height: 100%;
        overflow: auto;
        .table {
          width: 100%;
          border-spacing: 0px;
          tr {
            td:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th,
            td {
              padding: 15px 10px;
              text-align: center;
              border-bottom: 2px solid #dcdfe6;
              border-right: 2px solid #dcdfe6;
              background: #fff;
              .comm_box {
                .boolean_box {
                  .van-checkbox {
                    z-index: 0;
                    justify-content: center;
                    /deep/.van-checkbox__icon {
                      .van-icon {
                        background: #fff;
                      }
                    }
                    /deep/.van-checkbox__icon--checked {
                      .van-icon {
                        color: #fff !important;
                        background-color: #1989fa !important;
                        border-color: #1989fa !important;
                      }
                    }
                    /deep/.van-checkbox__icon--disabled .van-icon {
                      background-color: rgba(234, 236, 238, 0.39);
                    }
                  }
                }
                .van-checkbox.hasbook {
                  /deep/.van-checkbox__icon {
                    .van-icon {
                      background: #1989fa60;
                      color: #fff !important;
                    }
                  }
                }
                .text_box {
                  font-size: 24px;
                }
              }
            }
            td.disabled {
              background-color: rgba(234, 236, 238, 0.39);
            }
          }
          .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;
            z-index: 1;
            th:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th {
              white-space: nowrap;
              .title {
                white-space: nowrap;
                img {
                  vertical-align: middle;
                }
              }
            }
          }
          .sum {
            height: 64px;
            font-size: 28px;
            position: sticky;
            bottom: 0;
            z-index: 1;

            td {
              background: #d6e6fb;
            }
          }
        }
      }
    }
    .form_content {
      /deep/.van-collapse {
        height: 100%;
        overflow-y: auto;
        .van-collapse-item {
          margin: 20px;
          .van-collapse-item__title {
            border-radius: 20px 20px 0 0;
            .van-cell__title {
              font-size: 32px;
              font-weight: bold;
              font-family: Source Han Sans CN;
              color: #000;
              .title_bg {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                img {
                  height: 100%;
                }
              }
              .xiba_title {
                display: flex;
                align-items: center;
                position: relative;
                .van-icon {
                  margin-right: 16px;
                }
              }
            }
          }
          .van-collapse-item__wrapper {
            border-radius: 0 0 20px 20px;
            .van-collapse-item__content {
              .peField {
                .van-field {
                  .van-field__label {
                    color: #aeaeae;
                    width: 8em;
                  }
                  .van-field__value {
                    color: #2a2a2a;
                  }
                }
                .not_canedi {
                  .van-field__value {
                    .van-field__body {
                      .van-field__control {
                        color: #2a2a2a;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .van-hairline--top-bottom::after {
        border: unset;
      }
      .noGroup_box {
        height: 100%;
        overflow-y: auto;
        .noGroup_form {
          padding: 0 50px;
          background-color: #fff;
          .peField {
            .van-field {
              .van-field__label {
                color: #aeaeae;
                width: 8em;
              }
              .van-field__value {
                color: #2a2a2a;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 95%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
